.input_types {
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    padding: 0px 20px;
    margin-top: 5px;
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 20px;
    color: #111111;
    align-self: center;
}

.input_types::placeholder {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 20px;
    color: #828282;
    align-self: center;
}

.select_text {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 20px;
    color: #111111;
    align-self: center;
    background: none;
    padding: 0px 0px 0px 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.label_text {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 28px;
    line-height: 33px;
    color: #828282;
}

.select_border {
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 48px;
    margin-top: 7px;
}

.border_start_select {
    /* border: none; */
    -webkit-appearance: none;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat calc(100% + 14px) 50%;
    background-size: 40px 40px;
}

.Mode_of_payment_button {
    width: 100%;
}

@media only screen and (min-width: 440px) {
    .Mode_of_payment_button {
        display: flex !important;
    }
}

@media only screen and (max-width: 440px) {
    .Mode_of_payment_button {
        display: block !important;
    }
}

.button {
    width: 260px;
    height: 52px;
    left: 0px;
    top: 0px;
    background: #92BDE3;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 28px;
    line-height: 33px;
    color: #FFFFFF;
}

@media only screen and (max-width: 990px) {
    .button {
        width: 100px;
        height: 52px;
        left: 0px;
        top: 0px;
        background: #92BDE3;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: none;
    }
}

.input_types_email {
    width: 100% !important;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    padding: 0px 20px;
}

@media only screen and (min-width: 990px) {
    .input_types_email {
        width: 40% !important;
        height: 52px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: none;
        padding: 0px 20px;
    }
}

.btn-styles {
    border: none;
    background: none;
    margin-top: 5px;
}

.input-text {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 28px;
    line-height: 33px;
    color: #828282;
}

.box {
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.wrapper {
    display: inline-flex;
    background: #fff;
    height: 40px;
    width: 200px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
}

.wrapper .option {
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
}

.wrapper .option .dot {
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
}

.wrapper .option .dot::before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #0069d9;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}

input[type="radio"] {
    display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2 {
    border-color: #0069d9;
    background: #0069d9;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot {
    background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before {
    opacity: 1;
    transform: scale(1);
}

.wrapper .option span {
    font-size: 20px;
    color: #808080;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span {
    color: #fff;
}

.submit_button {
    min-width: 260px;
    height: 52px;
    background: #0B5ED7;
    border-radius: 10px;
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 28px;
    line-height: 33px;
    color: #FFFFFF;
    border: none;
}

select:focus {
    outline: none;
    /* border: 2px solid blue; */
    /* box-shadow: 0 0 5px blue; */
    /* add other styles as desired */
}


@media only screen and (min-width: 990px) {
    .volume_set_mt_sm {
        margin: 17px 0px 0px 0px !important;
    }
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 35px;
    height: 35px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.line {
    width: 1px;
    background: #000000;
}

.bg {
    background: url('../../../images/input_type_image.png') no-repeat 98% !important;
    background-size: 25px 25px !important;
    height: 100% !important;
    /* padding: 0px;
    margin: 0p=; */
    border: none !important;
    padding: 9px;
}
