.Submitmainbackground {
    background: url(../../images/bg1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.MYlogortitle {
    font-family: SF Pro;
    font-weight: bold !important;
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    color: #000000;

    border-bottom: 5px solid #B6AAAA;
}

.titleheadSubmit {
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 28px;
    color: #828282;
    margin-bottom: 0;
}

.SubitController {
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    padding: 0 15px;
    border: none;
    border-radius: 10px !important;
}

.SubitController::placeholder {
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #000000;

}


.form-select {
    height: 52px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    padding: 0 15px;
    border: none;
    outline: none;
    border: none;

}

.SubitController2 {
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    padding: 0 15px;
    border: none;
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-right: 2px solid;
}

.SubitController2::placeholder {
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #000000;

}

.rightborder {
    height: 52px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    padding: 0 15px;
    border: none;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;

}

.Selectmaterial {
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #000000
}


.Payment-input-group {
    display: flex;
}

@media screen and (max-width:768px) {
    .Payment-input-group {
        display: flex;
        flex-wrap: wrap;
    }

}

@media screen and (max-width:767px) {

    .PaymentText {
        max-width: 200px !important;
    }

    .PaymentText2 {
        max-width: 200px !important;

    }

    /* .SubmitBtn {
        justify-content: start !important;
        
    
    } */
}

#PaymentText {
    width: 400px;
    height: 52px;
    background: #92BDE3;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    outline: none;
    margin-right: 30px;
    margin-left: 0px;
    display: block;
    font-size: 28px;
    color: #FFFFFF;
}

#PaymentText2 {
    width: 400px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    outline: none;
    margin-right: 30px;
    margin-left: 0px;
    display: block;
    color: #828282;
    font-size: 28px;

}

.SubmitBtn {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: fixed !important;
    bottom: 0;

}

.SubmitText {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 52px;
    left: 592px;
    top: 922px;
    text-decoration: none;
    background: #92BDE3;
    border-radius: 10px;
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 28px;
    line-height: 33px;

    color: #FFFFFF;

}

.imgput {
    background-color: #FFFFFF !important;
}

.active {
    background-color: #92BDE3;
}