/* .main-div{
    border:1px solid gray;
    padding: 10px;
    border-radius: 10px;
} */

.paytex {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    text-align: center;
    color: #585858;

}

.images {
    display: inline-block;
    max-width: 98%;
    height: auto;
    width: 22%;
    margin: 1%;
    left: 20px;
    text-align: center
}

.texhed {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 22px;
    color: #828282;

}

.amount {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    color: #828282;

}

.form-control {
    height: 52px;

    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

}

.form-control::placeholder {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 24px;
    color: #D5D5D5 !important;


    /* identical to box height */
    color: #000000;

}

.abc {
    margin: 10px;
    height: 54px;
    border-radius: 10px;

}

.mybtPay {

    background-color: #92BDE3;
    color: #FFFFFF;
    width: 200px;
    height: 52px;
    border: none;
    border-radius: 10px;
}

input::-webkit-outer-spin-button,

input::-webkit-inner-spin-button {

    -webkit-appearance: none;

    margin: 0;

}


input[type=number] {

    -moz-appearance: textfield;

}

/* ================================ */
.mainbox {
    width: 553px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .mainbox {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.25);
        background: #FFFFFF;
        border-radius: 20px;
    }
}

.button_red{
    background: #fd5f33;
    color: #FFFFFF;
}