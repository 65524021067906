.title-name {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    color: #000000;
    text-align: center;
    margin: 10px auto;
}

@media only screen and (max-width: 600px) {
    .title-name {
        font-family: 'SF-Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 72px;
        color: #000000;
        text-align: center;
        margin: 10px auto;
    }
}

.head-div {
    width: 100%;
    border-bottom: 5px solid #B6AAAA;
}

.InvoiceList-H {
    height: 100vh;
    width: 100%;
    /* background: #ac5555; */
    padding: 100px 0px 0px 0px;
}