.login_page_bg {
  width: 100%;
  height: 100vh;
  background: url(../../images/bg1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login_image {
  height: 100%;
  width: 100%;
}

.logo-div {
  height: 215px;
  width: 215px;
  margin: 0px auto;
  display: block;
}

.logo_image {
  border-radius: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.form_title_bold {
  font-family: "SF-Pro";
  font-style: normal;
  font-weight: 590;
  font-size: 45px;
  line-height: 54px;
  color: #000000;
}

.form_title_muted {
  font-family: "SF-Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.4);
}

.login_button {
  height: 50px;
  background: #92bce3;
  border-radius: 10px;
  border: none;
  font-family: "SF-Pro";
  font-style: normal;
  font-weight: 510;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
}

.input_type_seting {
  border: 1px solid #53a8d0 !important;
  border-left: none !important;
  padding: 0px 0px 0px 20px;
}

input:focus {
  outline: #00bfff solid 0px !important;
}
