.padding_set {
    padding: 30px 40px !important;
}

@media only screen and (max-width: 600px) {
    .padding_set {
        padding: 0px 0px !important;
    }
}

@media only screen and (max-width: 400px) {
    .padding_set {
        padding: 0px 0px !important;
    }
}

.Select_year_button {
    min-width: 397px;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
}

.button_text {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 30px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.5);
}

.layout_set {
    margin: 0px 0px 0px 0px
}


.month_button {
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 510;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
}

@media only screen and (max-width: 991px) {
    .month_button {
        width: auto !important;
        height: 60px;
        background: #f6f6f6 !important;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: none;
        font-family: 'SF-Pro';
        font-style: normal;
        font-weight: 510;
        font-size: 30px;
        line-height: 36px;
        color: #000000;
    }
}

@media only screen and (max-width: 1171px) {
    .month_button {
        width: 100% !important;
        height: 60px;
        background: #f6f6f6 !important;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: none;
        font-family: 'SF-Pro';
        font-style: normal;
        font-weight: 510;
        font-size: 25px;
        line-height: 36px;
        color: #000000;
    }
}

.Invoice_link_button {
    background: #FFFFFF;
    width: 100%;
    height: 60px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 1171px) {
    .Invoice_link_button {
        background: #FFFFFF;
        width: 100%;
        height: 60px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: none;
        font-family: 'SF-Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 36px;
        color: rgba(0, 0, 0, 0.5);
    }
}

.spinner {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.spinner span {
    font-size: 2rem;
    animation: fade 1s linear 0s infinite;
    padding-right: 1rem;
}

.half-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #090909;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.5s linear 0s infinite;
}

.completed {
    font-size: 2rem;
    color: #090909;
    animation: bigger 1s linear;
}

@keyframes bigger {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(2);
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}